<template>
  <div>
    <div class="auth-wrapper auth-v1" v-if='submited==true'>
      <v-progress-circular
        :size="100"
        :width="7"
        color="primary"
        indeterminate

      ></v-progress-circular>
    </div>
    <v-card
      v-else
      class="mx-auto"
    >
      <v-snackbar
        v-model="snackbar.show"
        :timeout="snackbar.timeout"
        :color="snackbar.color"
        :top="true"
        :right="true"
        transition="scroll-x-reverse-transition"
      >
        {{ snackbar.message }}
      </v-snackbar>
      <v-card-title>
        Enregistrement de la catégorie de permission
      </v-card-title>
      <v-divider></v-divider>
      <v-row>
        <v-form
          id="catForm"
          ref="catForm"
          class="multi-col-validation col-md-10 offset-md-1"
        >
          <fieldset class="py-6 mb-4">
            <legend class="ml-4 pl-2 pr-2">
              Enregistrer une catégorie de département
            </legend>
            <v-row class="mx-auto">
              <v-col
                cols="12"
                offset-md='2'
                md="8"
              >
                <v-select
                  v-model="cat.departement_id"
                  outlined
                  dense
                  :items="departement"
                  :rules="LocalNameRules"
                  item-text="name"
                  item-value="id"
                  label="Département"
                ></v-select>

              </v-col>

              <v-col
                cols="12"
                offset-md='2'
                md="8"
              >
                <v-text-field
                  v-model="cat.name"
                  label="Nom"
                  :rules="LocalNameRules"
                  outlined
                  dense
                  placeholder="Nom"
                  hide-details="auto"
                ></v-text-field>
              </v-col>


              <v-col
                cols="12"
                offset-md='2'
                md="8"
              >
                <v-text-field
                  v-model="cat.icon"
                  label="Icone"
                  :rules="LocalNameRules"
                  outlined
                  dense
                  placeholder="Icone"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
          </fieldset>
          <v-row
            class=""
          >
            <v-col
              cols="12"
              class="d-sm-flex flex-sm-row-reverse"
            >
              <v-btn
                color="primary"
                class="ml-sm-6 col-sm-2 mt-1 mb-2"
                @click="saveDept"
              >
                Enregistrer
              </v-btn>
              <v-btn
                type="button"
                class="ml-sm-6 col-sm-2 mt-1 mb-2"
                @click="resetForm"
                outlined
              >
                Annuler
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-row>
    </v-card>
  </div>

</template>

<script>

import { reactive } from '@vue/composition-api'
import Http from '@/helpers/http'
import { ref } from '@vue/composition-api/dist/vue-composition-api'
import router from '@/router'

export default {
  name: 'AddFormRefill',
  setup() {
    // Règle de validation d'un champ
    const LocalNameRules = [
      v => !!v || 'Ce champ est réquis',
    ]

    const snackbar ={
      show: false,
      message: '',
      color: 'success',
      timeout: 2000,
    }

    const cat = reactive({
      name: '',
      departement_id: '',
      icon: '',
    })

    const submited = false

    const departement = ref([])

    return {
      LocalNameRules,
      cat,
      snackbar,
      submited,
      departement,
    }
  },
  computed: {
    applyRules() {
      if (this.cat.typeDeposit !== 1) {
        return this.LocalNameRules
      }

      return []
    },
  },
  watch: {
  },
  created() {
    this.getDepartement()
  },
  methods: {

    getDepartement() {
      Http.get('departement')
        .then(async response => {
          // eslint-disable-next-line no-multi-assign
          this.departement = await response.data
        })
    },

    saveDept() {
      if (this.$refs.catForm.validate()) {
        const params =  this.cat

        this.submited = true;

        // console.log(this.cat)
        Http.post(
          'permission-categorie',
          params,
        ).then(rep => {
          this.submited = false;
          this.snackbar.message = 'Enregistrement effectué avec succès'
          this.snackbar.color = 'success'
          this.snackbar.show = true

          setTimeout(() => {
            router.push({ name: 'list-cat-permission' })
          }, 1000)

        }).catch(error => {
          this.submited = false;
          if (error.status === 422) {
            this.snackbar.message = error.data.errors
          } else if (error.status === 401) {
            this.snackbar.message = error.data
          } else {
            this.snackbar.message = 'Votre connexion internet est instable'
          }
          this.snackbar.color = 'error'
          this.snackbar.show = true
        })
      }
    },
    resetForm() {
      this.$refs.catForm.reset()
    },
  },
}
</script>
<style lang="scss">
@import '~@/@core/preset/preset/pages/auth.scss';
.errorMessage {
  color: #f20808 !important;
}
</style>
